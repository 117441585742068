import { WppTypography } from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import assistantAvatarImgSrc from 'assets/assistant-avatar.png'
import { Avatar } from 'components/avatar/Avatar'
import styles from 'components/botChatBubble/BotChatBubble.module.scss'
import { Flex } from 'components/common/flex/Flex'

interface Props {
  message: string
  actions?: { label: string; action?: () => void }[]
  showAvatar?: boolean
}

export const BotChatBubble = ({ message, actions, showAvatar }: Props) => {
  const [showActions, setShowActions] = useState(true)

  const handleActionClick = (action: any) => {
    if (!action) return
    setShowActions(false)
    action()
  }

  return (
    <div>
      <Flex gap={12}>
        {showAvatar && <Avatar size="l" src={assistantAvatarImgSrc} />}
        <div>
          <div className={clsx(styles.bubble, !showAvatar && styles.noAvatarBubble)}>
            <WppTypography type="m-body" className={styles.message}>
              {message}
            </WppTypography>
          </div>
          {actions && showActions && (
            <Flex gap={8} className={clsx(styles.actions, !showAvatar && styles.noAvatarActions)}>
              {actions.map((action, index) => (
                <button key={index} className={styles.actionBtn} onClick={() => handleActionClick(action?.action)}>
                  <WppTypography type="m-strong">{action.label}</WppTypography>
                </button>
              ))}
            </Flex>
          )}
        </div>
      </Flex>
    </div>
  )
}
