import { useOs } from '@wppopen/react'
import { Fragment, useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import styles from 'app/App.module.scss'
import { BotChatBubble } from 'components/botChatBubble/BotChatBubble'
import { UserChatBubble } from 'components/userChatBubble/UserChatBubble'

export function App() {
  const { osContext } = useOs()

  const [chatProgressIndex, setChatProgressIndex] = useState(0)

  const handleContinueChat = () => {
    if (chatProgressIndex === chat.length - 1) return
    setChatProgressIndex(chatProgressIndex + 1)
  }

  const chat = [
    {
      type: 'bot',
      message: `Hello ${osContext?.userDetails?.firstname},\n\nWelcome to WPP’s Open Henkel Academy!`,
      showAvatar: true,
      timeout: 500,
    },
    {
      type: 'bot',
      message: 'Is this your first visit to Henkel Academy?',
      actions: [{ label: 'YES', action: handleContinueChat }, { label: 'NO' }],
    },
    {
      type: 'user',
      message: 'Yes',
      timeout: 500,
    },
    {
      type: 'bot',
      message: `In that case ${osContext?.userDetails?.firstname}, we’re delighted to see you here for the first time!`,
      showAvatar: true,
      timeout: 500,
    },
    {
      type: 'bot',
      message: 'Henkel Academy has been designed to help onboard you to working on the Henkel account at WPP.',
      actions: [{ label: 'Got it!', action: handleContinueChat }],
    },
    {
      type: 'user',
      message: 'Got it!',
      timeout: 500,
    },
    {
      type: 'bot',
      message:
        'Henkel is a long-term partner of WPP.\n\nYou’ll settle in quickly and soon you’ll be doing some of the best work of your career with some incredibly talented colleagues across the world.',
      showAvatar: true,
      actions: [{ label: 'Sounds great!', action: handleContinueChat }],
    },
    {
      type: 'user',
      message: 'Sounds great!',
      timeout: 500,
    },
    {
      type: 'bot',
      message:
        'We’ll be covering a wide range of topics in Henkel Academy, but we’ll also make sure that you’re introduced to all of the right team members to answer any questions you may still have.',
      showAvatar: true,
      actions: [{ label: 'Understood', action: handleContinueChat }],
    },
    {
      type: 'user',
      message: 'Understood',
      timeout: 500,
    },
    {
      type: 'bot',
      message: 'So let’s get started!',
      showAvatar: true,
      timeout: 500,
    },
    {
      type: 'bot',
      message:
        'Henkel Academy consists of engaging learning videos. We recommend you initially navigate these in order as they’ve been designed in a specific sequence. You can come back and reference them at any time!',
      timeout: 500,
    },
    {
      type: 'bot',
      message:
        'There are downloadable guides supporting each episode to help your knowledge retention.\n\nWe encourage you to take the quiz at the very end to test your knowledge – and earn a certificate which you can display proudly!',
      timeout: 500,
    },
    {
      type: 'bot',
      message: 'In addition, you’ll find templates and guides for creating key documents used for Henkel.',
      actions: [{ label: 'Got it!', action: handleContinueChat }],
    },
    {
      type: 'user',
      message: 'Got it!',
      timeout: 500,
    },
    {
      type: 'bot',
      message: 'Alright, let’s get started! Here’s the first episode of Henkel Academy. Dive on in!',
      showAvatar: true,
    },
  ]

  useEffect(() => {
    if (chatProgressIndex === chat.length - 1) return

    if (chat[chatProgressIndex]?.timeout) {
      setTimeout(() => {
        setChatProgressIndex(chatProgressIndex + 1)
      }, chat[chatProgressIndex].timeout)
    }
    // eslint-disable-next-line
  }, [chatProgressIndex])

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <div className={styles.container}>
        {chat.map((chatItem, index) => (
          <Fragment key={index}>
            {index <= chatProgressIndex && (
              <>
                {chatItem.type === 'bot' && (
                  <BotChatBubble
                    message={chatItem.message}
                    actions={chatItem?.actions}
                    showAvatar={chatItem?.showAvatar}
                  />
                )}
                {chatItem.type === 'user' && <UserChatBubble message={chatItem.message} />}
              </>
            )}
          </Fragment>
        ))}
      </div>
    </BrowserRouter>
  )
}
