import { WppTypography } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'

import { Avatar } from 'components/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/userChatBubble/UserChatBubble.module.scss'

interface Props {
  message: string
}

export const UserChatBubble = ({ message }: Props) => {
  const { osContext } = useOs()

  return (
    <Flex justify="end">
      <Flex gap={12}>
        <div className={styles.bubble}>
          <WppTypography type="m-body" className={styles.message}>
            {message}
          </WppTypography>
        </div>
        <Avatar size="m" name={osContext?.userDetails?.firstname} />
      </Flex>
    </Flex>
  )
}
