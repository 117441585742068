// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fl45B{display:inline-block;margin-bottom:8px;padding:16px 24px;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-m)}.FAQ5t{white-space:pre-wrap}`, "",{"version":3,"sources":["webpack://./src/components/userChatBubble/UserChatBubble.module.scss"],"names":[],"mappings":"AAAA,OACE,oBAAA,CACA,iBAAA,CACA,iBAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,oBAAA","sourcesContent":[".bubble {\n  display: inline-block;\n  margin-bottom: 8px;\n  padding: 16px 24px;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.message {\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bubble": `Fl45B`,
	"message": `FAQ5t`
};
export default ___CSS_LOADER_EXPORT___;
